import GynecoHistoryTemplate from '@/modules/questionnaire/components/steps/common/medical-background/gyneco-history/GynecoHistoryTemplate';

import { BOOLEAN, MENOPAUSAL } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/MedicalBackground/GynecoHistory/GynecoHistoryTemplate',
  component: GynecoHistoryTemplate
};

const createStory = props => () => ({
  components: { GynecoHistoryTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><gyneco-history-template v-bind="$options.storyProps" /></div>'
});

export const base = createStory({
  pregnant: BOOLEAN.YES,
  pregnantVisible: true,
  menopausalState: MENOPAUSAL.PERIMENOPAUSAL,
  menopausalStateVisible: true,
  takingHRT: BOOLEAN.YES,
  takingHRTVisible: true,
  takingBirthControlPill: BOOLEAN.YES,
  takingBirthControlPillVisible: true,
  birthControlPillName: 'some medicament name',
  birthControlPillNameVisible: true,
  usesIUD: BOOLEAN.YES,
  usesIUDVisible: true
});

export const pregnantQuestion = createStory({
  pregnant: BOOLEAN.NO,
  pregnantVisible: true
});

export const menopausalStateQuestion = createStory({
  menopausalState: MENOPAUSAL.NO,
  menopausalStateVisible: true
});

export const takingHRTQuestion = createStory({
  takingHRT: BOOLEAN.NO,
  takingHRTVisible: true
});

export const takingBirthControlPillQuestion = createStory({
  takingBirthControlPill: BOOLEAN.NO,
  takingBirthControlPillVisible: true
});

export const birthControlPillNameQuestion = createStory({
  birthControlPillName: 'some medicament name',
  birthControlPillNameVisible: true
});

export const usesIUDQuestion = createStory({
  usesIUD: BOOLEAN.NO,
  usesIUDVisible: true
});
